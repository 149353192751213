.admin-rooms {
  .admin-room-content {
    text-align: left;

    .setting-cell {
      display: flex;
      align-items: center;
      justify-content: space-around;

      .image {
        width: 40px;
        height: 40px;
        margin-right: 5px;
        border: 1px solid #efefef;
        display: flex;
        align-items: center;
        overflow: hidden;
      }

      .btn-change-image {
        margin-right: 5px;

        input {
          display: none;
        }
      }
    }

    .alert {
      padding: 0 5px;
      margin-bottom: 5px;
    }
  }
}
