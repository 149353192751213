.rooms-container {
  .add-new-room {
    padding: 5px;
    border-bottom: 1px solid #efefef;
    background: #333;
    text-align: left;

    .btn {
      padding: 1px 5px;
      font-size: 14px;

      svg {
        margin-right: 3px;
      }
    }
  }

  .rooms {
    .room {
      background: #fff;
      padding: 1px;
      border-bottom: 1px solid #ccc;
      position: relative;
      cursor: pointer;
      color: #333;
      font-weight: 700;
      transition: all 0.1s linear;
      width: 99%;
      margin: 0 auto;

      &:hover,
      &:focus {
        background: #efefef;

        .image-content-wrapper {
          background: #efefef;
        }
      }

      &.active {
        background: #fff;
        cursor: default;
        border: 2px solid dodgerblue !important;
      }

      &:last-child {
        border-bottom: 0;
      }

      .room-info-photo {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;

        .room-info {
          text-align: left;
          max-width: calc(100% - 95px);

          .name {
            display: flex;
            align-items: center;
          }

          .locked-room {
            margin-right: 3px;
          }
        }

        .image-content-wrapper {
          margin-right: 5px;
          transition: all 0.1s linear;
        }
      }

      .room-statistics {
        background: #333;
        padding: 2px 5px;
        position: absolute;
        top: 3px;
        right: 3px;

        span {
          margin-left: 3px;
        }
      }

      .desc {
        font-size: 13px;
      }
    }
  }
}
