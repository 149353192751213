.connected-users {
  overflow-y: auto;

  .head {
    padding: 1px;
    text-align: center;
    color: #fff;
    background: #333;
    font-weight: 700;
    border-radius: 100px;
  }

  .connected-users-search-box {
    .form-control {
      height: 26px;
      font-weight: 700;
    }
  }
}
