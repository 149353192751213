.shortcuts {
  .add-shortcut {
    .form-group {
      margin-bottom: 5px;
    }

    .alert {
      padding: 2px 5px;
      direction: rtl;
      margin-bottom: 5px;
    }
  }
}
