.users-list {
  .user {
    background: #fff;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    border: 1px solid #333;
    padding: 1px;
    width: 99%;
    margin: 0 auto;
    border-bottom: 0;
    word-break: break-word;
    overflow: hidden;

    &.clickable {
      cursor: pointer;
    }

    .content {
      flex: 1;
      text-align: left;
      line-height: 1.2;
      position: relative;

      .username-gift {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: flex-start;
        padding-right: 20px;

        .gift {
          width: 20px;
          height: 20px;
          margin-right: 3px;
        }

        .username {
          font-weight: 700;
        }
      }

      .bio {
        font-size: 13px;
        font-weight: 700;
        color: gray;
        line-height: 20px;
      }

      .flag {
        position: absolute;
        top: 0;
        right: 2px;
      }
    }

    &:last-child {
      border-bottom: 1px solid #333;
    }

    .user-icon {
      height: 28px;
      overflow: hidden;
    }
  }
}
