@import "variabels";

.admin-panel {
  background: #fff;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  .admin-sidebar {
    width: 100px;
    border: 1px solid #333;
    border-radius: 5px;
    margin: 2px 0 2px 2px;
    overflow-y: auto;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        text-align: left;
        padding: 8px 12px;
        font-weight: 700;
        color: #337ab7;
        cursor: pointer;
        transition: all 0.2s;

        &:hover,
        &:focus {
          background: #efefef;
        }

        &.active {
          background: $trikwaz;
          color: #fff;
        }
      }
    }
  }

  .content {
    flex: 1;
    direction: ltr;
    padding: 10px;
    overflow-y: auto;
  }
}
