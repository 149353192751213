.admin-messages {
  .admin-messages-content {
    text-align: left;

    .form-group {
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .btn {
      svg {
        margin-right: 5px;
      }
    }

    textarea {
      min-height: 70px;
    }

    table {
      .btn {
        svg {
          margin-right: 0;
        }
      }
    }

    .alert {
      padding: 0 5px;
    }
  }
}
