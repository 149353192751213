@import "variabels";

.members {
  table {
    tr {
       td {
        word-break: break-word;
      }
    }
  }

  .width-200 {
    min-width: 200px !important;
    max-width: 200px !important;
  }

  .width-400 {
    min-width: 400px !important;
    max-width: 400px !important;
  }
}

.admin-members-modal {
  .head {
    background: $trikwaz;
    color: #ffff;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .member-name {
      padding-left: 5px;
    }
  }

  .body {
    .form-group {
      display: flex;
      align-items: center;
      flex-flow: row-reverse nowrap;
      justify-content: space-between;

      label {
        margin-right: 10px;
        background: $trikwaz;
        color: #fff;
        font-weight: 700;
        border-radius: 5px;
        padding: 0 5px;
      }

      .form-control {
        flex: 1;
      }

      .save-btn {
        margin-left: 10px;
      }
    }
  }
}
