.create-room-form {
  .form-group {
    display: flex;
    flex-flow: row-reverse nowrap;
    align-items: center;
    margin-bottom: 5px;

    label {
      margin-bottom: 0;
      font-size: 14px;
      margin-left: 5px;
      flex-basis: 95px;
      text-align: center;
    }

    .form-control {
      flex: 1 1;
      text-align: left;
      height: 30px;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #efefef;
    margin-top: 10px;
    padding-top: 5px;
  }
}
