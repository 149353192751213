.settings {
  padding: 5px;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;

  .other-settings {
    overflow-y: auto;
  }

  .form-group {
    margin-bottom: 5px;
    position: relative;
  }

  .personal-profile-form {
    background: #fff;
    margin-top: -5px;
    padding: 1px;
    position: relative;

    .personal-profile-label {
      position: absolute;
      top: 0;
      right: 1px;
    }

    .form-group:not(.no-flex) {
      display: flex;
      flex-flow: row nowrap;
    }

    .form-group {
      margin-bottom: 1px;
      text-align: left;

      input {
        flex: 1;
        text-align: left;
      }
    }

    .save-btn {
      position: absolute;
      right: 1px;
      bottom: 0;
      border: 1px solid #333;
      padding: 6px;
      line-height: 1;
      font-size: 13.5px;
      font-weight: 700;
      display: flex;
    }
  }

  input {
    height: 26px;
    width: 80%;
    display: inline-block;

    &.less {
      flex: 0 0 80px !important;
    }
  }

  select {
    height: 22px;
    background: #333;
    color: #fff;
    direction: ltr;
    font-weight: 700;
    padding: 0;

    option {
      color: #fff;
    }

    &:focus {
      background: #333;
      color: #fff;
    }
  }

  .settings-btn-wrapper {
    position: relative;

    .btn {
      width: 100%;
      padding: .25rem;
      border: 1px solid #333;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;

      span {
        flex: 1;
        text-align: center;
      }
    }

    input[type='file'],
    input[type='checkbox'] {
      display: none;
    }

    label {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      span {
        text-align: center;
        flex: 1;
      }
    }

    .avatar {
      width: 24px;
      height: 24px;
      overflow: hidden;
    }

    .btn-logout {
      svg {
        margin-right: 5px;
      }
    }
  }

  .color-picker-wrapper {
    position: absolute;
    bottom: 32px;
    right: 0;
    z-index: 999;

    .chrome-picker {
      > div {
        &:last-child {
          > .flexbox-fix {
            &:last-child {
              display: none !important;
            }
          }
        }
      }
    }
  }

  .js-color-wrapper {
    max-width: 85px;

    input {
      width: 100%;
    }
  }
}
