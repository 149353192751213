@import "variabels";

.permissions {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;

  .icons {
    margin-left: 30px;
    border: 2px solid $trikwaz;
    border-radius: 4px;
    flex: 0 0 auto;
    min-width: 100px;

    .icon {
      cursor: pointer;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      margin-top: 5px;

      &:first-child {
        margin-top: 0;
      }

      .image {
        img {
          max-height: 28px;
        }
      }

      .name {
        margin-left: 5px;
        max-width: calc(100% - 31px);
        overflow: hidden;
      }

      &.active {
        background: #3f93da;
        color: #fff;
      }
    }
  }

  .add-permission {
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    .form-control {
      margin-right: 5px;
      flex: 1;
    }
  }

  .permissions-content {
    text-align: left;

    form {
      .form-group {
        display: flex;
        align-items: center;
        flex-flow: row nowrap;
        justify-content: flex-start;
        margin-bottom: 1px;

        .form-control {
          width: auto;
          height: auto;
          padding: 0;

          &.fixed-width {
            width: 75px;
          }
        }

        label {
          background: $trikwaz;
          padding: 0 5px;
          border-radius: 4px;
          color: #fff;
          font-weight: 700;
          width: 100px;
        }

        &.flex-column {
          flex-flow: column nowrap;
          align-items: flex-start;
        }
      }

      .btn-save {
        svg {
          margin-right: 5px;
        }
      }

      .alert {
        padding: 0 5px;
        margin-bottom: 5px;
      }
    }
  }
}
