@import "variabels";

.sidebar {
  display: flex;
  flex-flow: column nowrap;
  width: 0;
  background: #f5f5f5;
  transition: all 0.1s linear;
  position: absolute;
  top: 0;
  bottom: 67px;
  right: 0;
  z-index: 999;
  border-radius: 0.5rem 0 0 0;


  .sidebar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: row nowrap;
    background: #9E9E9E;
    font-weight: 700;
    color: #fff;
    border-bottom: 1px solid #333;
    width: 0;
    padding: 0;
    overflow: hidden;

    .btn {
      line-height: 1.4;
    }
  }

  .sidebar-body {
    flex: 1;
    background: #C7C7C7;
    display: flex;
    flex-flow: column nowrap;
    overflow-y: auto;
    direction: ltr;

    &.wall {
      overflow: hidden;
    }
  }

  &.active {
    width: $sidebar-width;
    border: 2px solid #333;

    .sidebar-header {
      width: 100%;
      padding: 0 5px;
    }
  }
}
