.icons-listing {
  display: flex;
  flex-flow: row wrap;
  margin-top: 10px;
  max-width: 500px;

  .icon {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    margin-right: 10px;
    margin-bottom: 10px;
    overflow: hidden;

    &:last-child {
      margin-right: 0;
    }

    .image {
      width: 30px;
      height: 30px;
    }
  }
}