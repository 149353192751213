.media-element {
  .cover {
    cursor: pointer;
    display: inline-flex;

    .image {
      width: 85px;
    }
  }

  .youtube-image-icon {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    .youtube-icon {
      margin-right: 5px;
      font-size: 40px;
      transition: all 0.2s linear;
    }

    &:hover,
    &:focus {
      .youtube-icon {
        color: #CC0000;
      }
    }
  }
}
