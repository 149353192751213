.user-profile-modal {
  position: relative;

  .info {
    margin-bottom: 5px;
  }

  .image {
    height: 150px;
    max-width: 50%;
    margin: 0 auto 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .bio {
    text-align: center;
    word-break: break-word;
  }

  .actions {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;

    .btn {
      width: calc((100% - 15px) / 3);
      margin-top: 5px;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        margin-top: 0;
      }

      span {
        margin-left: 2px;
      }
    }
  }

  .change-nickname {
    border: 1px solid #333;
    display: flex;
    flex-flow: row nowrap;
    margin-top: 10px;
    padding: 5px;

    label {
      font-weight: 700;
    }

    .form-control {
      margin-right: 10px;
      outline: 0;
      height: 31px;
    }

    .btn {
      color: #fff;
    }
  }

  .other-settings {
    margin-top: 10px;
    text-align: left;

    .form-group,
    .alert {
      margin-bottom: 5px;
    }

    .form-control {
      height: 31px;
    }

    .alert {
      margin-top: 5px;
    }
  }

  .send-gift-modal {
    background: #fff;
    border-radius: 4px;
    z-index: 999;
    position: absolute;
    left: 0;
    top: 210px;
    width: 100%;
    border: 1px solid #777;
    overflow: hidden;
    display: none;

    .title {
      font-weight: 700;
      border-bottom: 1px solid #efefef;
      padding: 10px;
      background: #fafafa;
      text-align: left;
      position: relative;

      .close-gift-modal {
        cursor: pointer;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
      }
    }

    .content {
      display: flex;
      flex-flow: row-reverse wrap;
      padding: 10px;
      align-items: flex-start;

      .gift {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        border: 1px solid #efefef;
        margin-right: 3.5px;
        cursor: pointer;
        margin-bottom: 5px;
        padding: 5px;
        border-radius: 4px;
        position: relative;

        &:after {
          content: ' ';
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          background: rgba(0, 0, 0, .05);
          opacity: 0;
          z-index: 1;
          transition: all 0.2s linear;
        }

        img {
          position: relative;
          z-index: 9;
        }

        &:nth-child(9n) {
          margin-right: 0;
        }

        &:hover,
        &:focus {
          &:after {
            opacity: 1;
          }
        }
      }
    }

    .footer {
      border-top: 1px solid #efefef;
      padding: 10px;
    }

    &.active {
      display: block;
    }
  }

  .view-nicks-modal {
    background: #fff;
    border-radius: 5px;
    text-align: left;
    overflow: hidden;
    box-shadow: rgba(0,0,0,.2) 0 0 61px 16px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    display: none;

    &.active {
      display: block;
    }

    .head {
      background: gray;
      font-weight: 700;
      padding: 10px 5px;
      display: flex;
      align-items: center;
      flex-flow: row nowrap;
      justify-content: space-between;

      .title {
        color: #fff;
        display: flex;
        align-items: center;

        svg {
          margin-right: 3px;
        }
      }

      .close-modal {
        color: #333;
        font-size: 16px;
        cursor: pointer;
      }
    }

    .content {
      padding: 10px;
      max-height: 407px;
      overflow-y: auto;

      .user {
        font-weight: 700;
        border-bottom: 1px solid #ddd;
        margin-bottom: 5px;
        overflow: hidden;
        word-break: break-all;

        &:last-child {
          border-bottom: 0;
          margin-bottom: 0;
        }

        label {
          color: #fff;
          border-radius: 5px;
          background: #54bdda;
          padding: 3px;
        }

        span {
          color: #333;
        }
      }
    }
  }

  .country-room {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    .room {
      .btn {
        outline: 0;
        display: flex;
        align-items: center;
        color: #fff;
        padding: 0 5px;
        box-shadow: none;

        .room-image {
          width: 25px;
          height: 25px;
          overflow: hidden;
          margin-right: 2px;
        }
      }
    }

    .country {
      display: flex;
      align-items: center;
      flex-flow: row nowrap;

      .flag {
        margin-right: 5px;
      }
    }
  }
}
