.wall {
  height: 100%;
  flex-flow: column nowrap;
  justify-content: space-between;
  display: none;

  &.active-side-bar-component {
    display: flex;
  }

  .wall-banner {
    height: 38px;
  }

  .wall_upper-section {
    position: relative;
    height: 100%;
  }

  .wall_subtitle {
    color: #a72a2a;
    background: linen;
    font-size: 14px;
    text-align: center;
    font-weight: 700;
    padding: 3px;
  }

  .wall-messages {
    overflow-y: auto;
    position: absolute;
    top: 66px;
    left: 0;
    right: 0;
    bottom: 0;

    .wall-message {
      display: flex;
      flex-flow: row nowrap;
      padding: 5px;
      border-bottom: 1px solid #efefef;
      background: #fff;
      position: relative;

      &:last-child {
        border-bottom: 0;
      }

      &:after {
        content: ' ';
        display: table;
        clear: both;
      }

      .info {
        flex: 1;
        text-align: left;
        position: relative;

        .sender-name {
          margin-bottom: 2px;
          flex-flow: row wrap;
        }

        .sender-name,
        .message-content {
          line-height: 1.2;
          word-break: break-word;
          padding-right: 30px;
        }

        .message-content {
          font-weight: 700;
          text-align: center;
        }

        .message-time {
          position: absolute;
          top: 5px;
          right: 5px;
        }

        .wall-message_actions {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          > a {
            text-decoration: none;
            margin-left: 3px;
            color: #fff;
            font-size: 12px;
            padding: 2px;
            border-radius: 6px;

            &:first-child{
              margin-left: 0;
            }

            &.wall-message_actions_like {
              background: red;
            }

            &.wall-message_actions_comment {
              background: #d398c1AA;
            }

            span {
              margin-left: 2px;
            }
          }
        }
      }

      .icon {
        width: 18.2px;
        float: left;
      }

      .wall-message_user-image-remove-message {
        position: relative;

        .delete-message {
          position: absolute;
          left: -3px;
          bottom: -3px;
          cursor: pointer;
          color: #fff;
          background: red;
          border-radius: 100%;
          line-height: 1;
          font-size: 11px;
          padding: 2px;
          opacity: 0.75;
          transition: all 0.2s linear;

          &:hover,
          &:focus {
            opacity: 1;
          }
        }
      }
    }
  }

  .message-input {
    border-top: 1px solid #333;
    background: #C7C7C7;
    direction: rtl;
  }
}
