@import "base";

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent
}

body {
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #333;
  text-align: right;
}

#wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: center;
  font-size: 15px;
  background-color: rgb(64, 64, 79);

  &.font-size-120 {
    font-size: 120%;
  }

  &.font-size-110 {
    font-size: 110%;
  }

  &.font-size-105 {
    font-size: 105%;
  }

  &.font-size-95 {
    font-size: 95%;
  }

  &.font-size-90 {
    font-size: 90%;
  }
}
