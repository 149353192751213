@import "variabels";

.chat-heading {
  display: flex;
  flex-flow: column nowrap;
  text-align: center;
  background: antiquewhite;
  position: relative;
  padding: 3px 15px;
  cursor: pointer;
  overflow: hidden;
  box-shadow: rgba(0,0,0,.5) 1px 0 2px 0px;
  max-height: $chat-heading-height;
  transition: max-height 0.15s ease-out;
  color: #105873;
  border-bottom: 3px solid #105873;
  border-top: 3px solid #6b5f5f;
  border-radius: 0px 0px 15px 15px;

  &.active {
    max-height: 500px;
    transition: max-height 0.25s ease-in;
  }

  .active-chat-room-name {
    align-self: flex-start;
  }
}
