@import "variabels";

.messages-container {
  max-height: calc(100vh - #{$chat-heading-height} - #{$message-input-height} - #{$chat-actions-height});
  overflow-y: auto;
  flex: 1;
  direction: ltr;
  background: #dadada;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;

  .messages {
    .message {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
      background: #fff;
      padding: 0;
      text-align: left;
      border: 1px solid lavender;
      border-bottom: 0;
      position: relative;
      border-radius: 5px;
      margin-left: 2px;
      max-width: 99.5%;
      overflow: hidden;

      * {
        font-weight: 700;
      }

      .image-content-wrapper {
        width: 36px;
        height: 38px;
        margin-top: 1px;
        margin-left: 1px;

        &.clicked {
          cursor: pointer;
        }
      }

      .message-user {
        line-height: 20px;
        width: 100%;
        max-width: calc(100% - 46px);
      }

      .message-info {
        word-break: break-word;
        line-height: 1;
        direction: rtl;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-end;
        margin-top: 5px;

        .btn {
          margin-right: 2px;
          padding-top: 2px;
          padding-bottom: 2px;
          outline: 0;

          svg {
            margin-right: 2px;
          }
        }
      }

      .bio {
        color: #8e8e8e;
        font-size: 12px;
      }

      .message-time {
        position: absolute;
        right: 5px;
        top: 5px;
      }

      &.highlighted-message {
        background-color: linen;
      }

      .icon {
        width: 18.2px;
        display: inline-block;
      }

      .adv {
        color: blue;
        font-weight: 700;
        margin-right: 5px;

        svg {
          margin-right: 2px;
        }
      }

      .delete-message {
        position: absolute;
        bottom: 2px;
        right: 3px;
        cursor: pointer;
      }
    }
  }
}
