.icons-listing-with-upload {
  margin-top: 40px;

  label {
    cursor: pointer;
  }

  .name {
    svg {
      margin-right: 3px;
    }
  }

  input[type=file] {
    display: none;
  }
}