@import "variabels";

*,
::after,
::before {
  box-sizing: border-box
}

* {
  font-family: serif;
}

@-ms-viewport {
  width: device-width
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block
}

.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media screen and (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25)
}

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1
}

.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1
}

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1
}

.form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff
}

.alert {
  position: relative;
  padding: .3rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
  direction: rtl;

  &.fixed {
    position: fixed;
    width: 160px;
    text-align: right;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.alert-heading {
  color: inherit
}

.alert-link {
  font-weight: 700
}

.alert-dismissible {
  padding-right: 4rem
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: .75rem 1.25rem;
  color: inherit
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff
}

.alert-primary hr {
  border-top-color: #9fcdff
}

.alert-primary .alert-link {
  color: #002752
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db
}

.alert-secondary hr {
  border-top-color: #c8cbcf
}

.alert-secondary .alert-link {
  color: #202326
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb
}

.alert-success hr {
  border-top-color: #b1dfbb
}

.alert-success .alert-link {
  color: #0b2e13
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb
}

.alert-info hr {
  border-top-color: #abdde5
}

.alert-info .alert-link {
  color: #062c33
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba
}

.alert-warning hr {
  border-top-color: #ffe8a1
}

.alert-warning .alert-link {
  color: #533f03
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb
}

.alert-danger hr {
  border-top-color: #f1b0b7
}

.alert-danger .alert-link {
  color: #491217
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe
}

.alert-light hr {
  border-top-color: #ececf6
}

.alert-light .alert-link {
  color: #686868
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca
}

.alert-dark hr {
  border-top-color: #b9bbbe
}

.alert-dark .alert-link {
  color: #040505
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    transition: none
  }
}

.btn:focus,
.btn:hover {
  text-decoration: none
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25)
}

.btn.disabled,
.btn:disabled {
  opacity: .65
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc
}

.btn-primary.focus,
.btn-primary:focus {
  box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .5)
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .5)
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62
}

.btn-secondary.focus,
.btn-secondary:focus {
  box-shadow: 0 0 0 .2rem rgba(108, 117, 125, .5)
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(108, 117, 125, .5)
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34
}

.btn-success.focus,
.btn-success:focus {
  box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .5)
}

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430
}

.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .5)
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b
}

.btn-info.focus,
.btn-info:focus {
  box-shadow: 0 0 0 .2rem rgba(23, 162, 184, .5)
}

.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8
}

.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f
}

.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(23, 162, 184, .5)
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00
}

.btn-warning.focus,
.btn-warning:focus {
  box-shadow: 0 0 0 .2rem rgba(255, 193, 7, .5)
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107
}

.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500
}

.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(255, 193, 7, .5)
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130
}

.btn-danger.focus,
.btn-danger:focus {
  box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .5)
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545
}

.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d
}

.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .5)
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5
}

.btn-light.focus,
.btn-light:focus {
  box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5)
}

.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df
}

.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5)
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124
}

.btn-dark.focus,
.btn-dark:focus {
  box-shadow: 0 0 0 .2rem rgba(52, 58, 64, .5)
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40
}

.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d
}

.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(52, 58, 64, .5)
}

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff
}

.btn-outline-primary.focus,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .5)
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .5)
}

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d
}

.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 .2rem rgba(108, 117, 125, .5)
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent
}

.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d
}

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(108, 117, 125, .5)
}

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745
}

.btn-outline-success.focus,
.btn-outline-success:focus {
  box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .5)
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent
}

.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745
}

.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .5)
}

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8
}

.btn-outline-info.focus,
.btn-outline-info:focus {
  box-shadow: 0 0 0 .2rem rgba(23, 162, 184, .5)
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent
}

.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8
}

.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(23, 162, 184, .5)
}

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107
}

.btn-outline-warning.focus,
.btn-outline-warning:focus {
  box-shadow: 0 0 0 .2rem rgba(255, 193, 7, .5)
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent
}

.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107
}

.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(255, 193, 7, .5)
}

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545
}

.btn-outline-danger.focus,
.btn-outline-danger:focus {
  box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .5)
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent
}

.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545
}

.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .5)
}

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa
}

.btn-outline-light.focus,
.btn-outline-light:focus {
  box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5)
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent
}

.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa
}

.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5)
}

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40
}

.btn-outline-dark.focus,
.btn-outline-dark:focus {
  box-shadow: 0 0 0 .2rem rgba(52, 58, 64, .5)
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent
}

.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40
}

.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(52, 58, 64, .5)
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  background-color: transparent
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent
}

.btn-link.focus,
.btn-link:focus {
  text-decoration: underline;
  border-color: transparent;
  box-shadow: none
}

.btn-link.disabled,
.btn-link:disabled {
  color: #6c757d;
  pointer-events: none
}

.btn-group-lg > .btn,
.btn-lg {
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .3rem
}

.btn-group-sm > .btn,
.btn-sm {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .2rem
}

.btn-block {
  display: block;
  width: 100%
}

.btn-block + .btn-block {
  margin-top: .5rem
}

input[type=button].btn-block,
input[type=reset].btn-block,
input[type=submit].btn-block {
  width: 100%
}

.form-group {
  margin-bottom: 1rem
}

.jewel-count {
  background: #888;
  color: #fff;
  display: flex;
  border-radius: 100px;
  font-size: 11px;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  padding: 1px 5px;

  &.green {
    background: #5cb85c;
  }
}

img {
  vertical-align: middle;
  border-style: none
}

svg {
  overflow: hidden;
  vertical-align: middle
}

.img-fluid {
  max-width: 100%;
  height: auto
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.modal-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  &.private-message {
    right: auto;
    bottom: auto;
  }

  .modal-content {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    border-radius: 2px;
    min-width: 340px;
    max-width: 340px;
    background: #fff;
    outline: 0;
    max-height: 75vh;
    overflow-y: auto;
    direction: ltr;
    overflow-x: hidden;

    &.messages-with-message-input-box {
      width: 500px;
      height: 37vh;
      display: flex;
      flex-flow: column nowrap;
      border-radius: 0 0 1rem 1rem;
      border: 1px solid #333;
      overflow: hidden;

      .body {
        flex: 1;
        display: flex;
        flex-flow: column;
        padding: 0;
        height: calc(100% - 33px);
        max-height: calc(100% - 33px);

        .messages-with-message-input {
          flex: 1;
          display: flex;
          flex-flow: column;
          height: 100%;
          overflow-y: auto;

          .messages-container {
            background: #C7C7C7;
            height: calc(100% - 45px);
          }

          .message {
            background: #fff !important;
          }
        }
      }

      .message-input {
        height: 45px;
        flex-direction: row;
        border-top: 1px solid #ddd;

        .message-input-text-field {
          height: 30px;
        }

        .send-message-form {
          flex-direction: row;
        }
      }
    }

    &.private-message {
      left: 0;
      top: 0;
      transform: translateX(0);

      &.expanded {
        height: 51vh;
      }
    }

    .head:not(.private-message-modal-header) {
      border-bottom: 1px solid #efefef;
      text-align: left;
      position: relative;

      .head-content {
        background: #333;
        border-radius: 100px;
        color: #fff;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        padding: 7px 12px;

        .image-content-wrapper {
          width: 20px;
          height: 20px;
          border: 0;
          margin-right: 5px;
        }

        .title {
          margin: 0;
          display: flex;
          align-items: center;

          svg {
            margin-left: 5px;
          }
        }

        svg {
          cursor: pointer;
          color: #fff;
        }
      }
    }

    .head.private-message-modal-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-flow: row nowrap;
      background: #9E9E9E;
      padding: 1px;
      font-weight: 700;
      color: #fff;

      .username-style {
        .image-content-wrapper {
          height: 30px;
          width: 30px;
        }
      }
    }

    .body {
      padding: 10px;
    }
  }
}

.flex-align-end {
  align-items: flex-end !important;
}

.flex-row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.flex {
  flex: 1;
}

.ml-10 {
  margin-left: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.width-40 {
  width: 40px;
}

.separator {
  height: 1px;
  background: #efefef;
  margin: 15px auto;
  width: 90%;
}

.fill-label {
  background: #333;
  color: #fff;
  border-radius: 200px;
  font-weight: 600;
  padding: .2em .6em .3em;
  line-height: 1;
  display: inline-flex;
  align-items: center;
}

.no-m {
  margin: 0 !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.btn-white {
  background: #fff;
  border: 1px solid #ced4da;
  text-decoration: none;
  color: #333;

  &:hover,
  &:focus {
    background: #f9f9f9;
  }
}

.btn-trikwaz {
  background: $trikwaz;
  border: 1px solid $trikwaz;
  text-decoration: none;
  color: #fff;

  &:hover,
  &:focus {
    background: $trikwaz;
  }
}

.image-content-wrapper {
  width: 36px;
  height: 36px;
  margin-right: 10px;
  border: 1px solid #efefef;
  border-radius: 3px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;

  &.clickable {
    cursor: pointer;
  }

  &.user-activity {
    position: relative;
  }
}

.digi-btn {
  border-radius: 6px;
  border: 1px solid #333;
}

.red-color {
  color: red;
}

table {
  width: 100%;
  border: 1px solid #ddd;
  border-collapse: collapse;

  thead {
    tr {
      th {
        color: #fff;
        padding: 10px;
        text-align: center;
        background: #6395ec;
        border-left: 1px solid #6083d7;
      }
    }
  }

  tbody {
    tr {
      border-top: 1px solid #dddd;

      &:first-child {
        border-top: 0;
      }

      td {
        padding: 5px;
        color: #333;
        border-left: 1px solid #ddd;
        text-align: center;
        min-width: 100px;

        &:first-child {
          border-left: 0;
        }
      }
    }
  }
}

.username-style {
  display: flex;
  align-items: center;

  .gift {
    width: 20px;
    height: 20px;
    margin-right: 3px;
  }

  .username-text {
    padding: 1px 3px;
    border-radius: 3px;
  }

  .user-icon {
    img {
      max-height: 16px;
    }
  }
}

.upload-file-progress {
  position: absolute;
  right: 2px;
  width: 0;
  height: 2px;
  top: 0;
  background: #28a745;
  max-width: calc(100% - 3px);
  transition: all 0.2s;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.user-activity-flag {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 4px;
  left: 0;
  z-index: 999;

  &.green {
    background: green;
  }

  &.yellow {
    background: yellow;
  }

  &.gray {
    background: gray;
  }

  &.red {
    background: red;
  }

  &.blue {
    background: blue;
  }
}

.btn {
  &.disabled {
    pointer-events: none;
  }
}

.message-time {
  font-size: 13px;
  font-weight: 700;
  color: gray;
}

.clearfix {
  &:after {
    content: ' ';
    display: table;
    clear: both;
  }
}

.ReactModalPortal {
  position: relative;
  z-index: 999;
}

.file-input {
  display: inline-block;
  padding: 5px;
  position: relative;
}

.img-preview {
  border: 1px solid #efefef;
  border-radius: 3px;
  position: relative;
  padding: 5px;
  max-width: 300px;

  &:after {
    content: ' ';
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border: 1px solid #efefef;
    border-radius: 4px;
  }

  &.mt {
    margin-top: 5px;
  }
}

.load-more {
  margin-top: 30px;
}

.cover-bg {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.hide-on-mobile {
  display: none;
}

@media all and (min-width: 481px) {
  .hide-on-mobile {
    display: block;

    &.inline {
      display: inline;
    }
  }
}

.search-box {
  margin-bottom: 15px;

  .btn {
    margin-left: 15px;
  }
}