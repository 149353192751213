.login-form-wrapper {
  padding: 10px;

  .login-form {
    label,
    .btn {
      font-size: 14px;
      font-weight: 700;
    }

    .btn {
      color: #fff;
    }

    .alert {
      flex-basis: 100%;
      padding: 5px 10px;
      margin-bottom: 5px;
    }

    .form-fields {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
    }

    .form-group {
      margin-bottom: 0;
      padding-right: 10px;
      width: 50%;
      text-align: left;

      &:first-child {
      }

      .form-control {
        height: 28px;
      }

      .btn {
        padding: 3px 9px;
      }

      &.mb-5 {
        margin-bottom: 5px;
      }
    }
  }
}
