@import "variabels";

.message-input {
  height: $message-input-height;
  background: #fff;
  display: flex;
  flex-flow: row-reverse nowrap;
  align-items: center;
  padding: 0 10px 0 3px;
  position: relative;

  > * {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }

  .leave-room {
    line-height: 1.22;
  }

  .emoji-icon-wrapper {
    width: 25px;
    cursor: pointer;

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .emojis {
    display: none;
    position: absolute;
    width: 276px;
    background: #fff;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, .2);
    bottom: 44px;
    left: 5px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .2);

    .emojis-container {
      height: 300px;
      overflow-x: hidden;
      overflow-y: auto;
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      align-content: flex-start;
      padding: 10px;
    }

    .emoji {
      width: 18.2px;
      height: 18.2px;
      overflow: hidden;
      margin-right: 8px;
      margin-top: 5px;
      cursor: pointer;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(8),
      &:nth-child(9),
      &:nth-child(10) {
        margin-top: 0;
      }

      &:nth-child(1),
      &:nth-child(11),
      &:nth-child(21),
      &:nth-child(31),
      &:nth-child(41),
      &:nth-child(51),
      &:nth-child(61),
      &:nth-child(71),
      &:nth-child(81),
      &:nth-child(91),
      &:nth-child(101),
      &:nth-child(111),
      &:nth-child(121),
      &:nth-child(131) {
        margin-right: 0;
      }
    }

    .triangle {
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 15px solid rgba(0, 0, 0, 0.2);
      position: absolute;
      bottom: -15px;
      left: 42px;

      .inner-one {
        width: 0;
        height: 0;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-top: 14px solid #fff;
        position: absolute;
        left: -9px;
        bottom: 2px;
      }
    }

    &.active {
      display: block;
    }
  }

  .send-message-form {
    display: flex;
    flex-flow: row-reverse nowrap;
    align-items: center;
    flex: 1;
    position: relative;

    > * {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }

    .btn {
      width: 80px;
      padding-top: 2px;
      padding-bottom: 2px;
    }

    .message-input-text-field {
      text-align: left;
      height: 27px;
      resize: none;
      line-height: .9;
      overflow-y: hidden;

      &.padding-right {
        padding-right: 35px;
      }
    }

    .jewel-count {
      position: absolute;
      top: 50%;
      right: 85px;
      transform: translateY(-50%);
    }
  }
}
