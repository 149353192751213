.notifications-container {
  $left: 30%;
  $right: 10%;

  .notification {
    background: #333;
    color: #fff;
    padding: 15px;
    position: absolute;
    top: $left;
    left: $right;
    border-radius: 5px;
    min-width: 200px;
    cursor: pointer;
    border: 1px solid #333;

    .notification-header {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      background: #333;
      color: #fff;
      top: -10px;
      padding: 5px 8px;
      border-radius: 3px;
      line-height: 1;
      border: 1px solid #333;
    }
  }

  @for $i from 2 through 10 {
    .notification-#{$i} {
      top: calc(#{$left} + (#{$i} * 5px));
      left: calc(#{$right} + (#{$i} * 5px));
    }
  }

  .notification-content {
    max-width: 320px;
    max-height: 200px;
    overflow: auto;

    .username {
    }

    .message-icon {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;

      img {
        max-height: 28px;
      }
    }
  }
}
