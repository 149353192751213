@import "variabels";

.chat-container {
  position: relative;
  background: #C7C7C7;
  flex: 1;
  height: 100%;
}

.chat-room-container {
  height: calc(100% - 31px);
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
}
