@import "variabels";

.chat-actions {
  height: $chat-actions-height;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  padding-left: 3px;
  background: #9E9E9E;
  align-items: center;

  .action {
    display: flex;
    align-items: center;
    padding: 5px;
    background: #333;
    margin-left: 2px;
    border-radius: 100px;
    line-height: 1;
    border: 1px solid #333;

    &:last-child {
      margin-left: 0;
    }

    svg {
      fill: #fff;
    }

    .jewel-count {
      margin-left: 5px;

      &.right {
        margin-right: 5px;
      }
    }

    &.active {
      background-color: #f0ad4e !important;
      border-color: #d09541 !important;
    }
  }
}
