.private-conversations {

  .private-conversation-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;

    &:last-child {
      border-bottom: 0;
    }

    &.unread {
      background: #FFC89D;
    }

    .btn {
      margin: 5px 5px 0 10px;
      padding: 0 5px;
      border: 1px solid #333;
    }

    .private-conversation {
      flex: 1 1;
      display: flex;
      flex-flow: row-reverse nowrap;
      justify-content: space-between;
      align-items: flex-start;
      cursor: pointer;
      background: #fff;
      border: 1px solid #333;
      padding: 2px;

      .image-content-wrapper {
        border: 0;
        width: 30px;
        height: 30px;
        border-radius: 0;
      }

      .conversation-name {
        flex: 1;
        text-align: left;
      }
    }
  }
}
