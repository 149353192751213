@import "variabels";

.filter {
  .filter-content {
    text-align: left;

    .form-group {
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .btn {
      svg {
        margin-right: 5px;
      }
    }

    .alert {
      padding: 0 5px;
      margin-bottom: 5px;
    }

    table {
      .btn {
        svg {
          margin-right: 0;
        }
      }
    }

    .filter-words-messages {
      margin-bottom: 35px;

      &:last-child {
        margin-bottom: 0;
      }

      .form-group {
        label {
          font-weight: 600;
        }
      }

      .filter-messages {
        margin-bottom: 10px;
        max-height: 300px;
        overflow-y: auto;

        .filter-messages-content {
          .content {
            border: 2px solid $trikwaz;
            padding: 10px;
            border-radius: 4px;
            margin-bottom: 5px;
          }

          .word {
            label {
              color: #fff;
              background: $trikwaz;
              border-radius: 4px;
              padding: 1px 4px;
              margin-right: 2px;
            }
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
