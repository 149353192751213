@import "base";

.home-login {
  display: flex;
  flex-flow: column nowrap;
  width: 380px;
  height: 100vh;
  border-radius: 3px;
  box-shadow: rgba(110, 110, 110, .2) 0 0 11px 0;
  background-color: #fff;
  border: 1px solid #333;
  overflow: hidden;

  .header {
    a {
      display: flex;
      flex-flow: row-reverse nowrap;
      align-items: center;
      justify-content: space-between;
      padding: 7px;
      text-decoration: none;

      .logo-app-name {
        display: flex;
        align-items: center;

        .logo {
          width: 28px;
          background: #fff;
          border-radius: 50%;
          margin-right: 5px;
        }

        .app-name {
          font-weight: 600;
          color: #fff;
        }
      }

      .btn-refresh {
        font-weight: 700;
        padding: 2px 5px;
      }
    }
  }

  .home-login-site-image {
    height: 68px;
    overflow: hidden;
    flex: 0 0 auto;
  }

  .site-administration {
    text-align: center;
    color: #fff;
    font-weight: 700;
    padding: 5px;
    cursor: pointer;
  }

  .site-administration-content {
    max-height: 0;
    overflow: hidden;
    background: #f5f5f5;
    border-radius: 0 0 20px 20px;
    border: 1px solid #e3e3e3;
    border-top: 0;
    transition: all 0.3s linear;
    flex: 0 0 auto;

    &.active {
      max-height: 200px;
    }

    .line {
      margin-bottom: 8px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      font-weight: 700;

      > * {
        margin-left: 3px;

        &:last-child {
          margin-left: 0;
        }
      }

      &:first-child {
        margin-top: 8px;
      }
    }
  }

  .home-login-actions {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 5px;

    .btn {
      color: #fff;
      margin-left: 10px;
      padding: 0 20px;
      border-radius: 100px;

      &:last-child {
        margin-left: 0;
      }
    }
  }

  .react-tabs__tab {
    font-weight: 700;
    font-size: 14px;
    padding: 8px 10px;
  }

  .react-tabs__tab--selected {
    border-radius: 20px 20px 0 0;
    color: #337ab7;
  }

  .react-tabs__tab-list {
    margin-top: 10px;
    padding: 0 15px;
    margin-bottom: 0;
  }

  .connected- {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 0 5px;
    margin-bottom: 5px;

    .connected {
      background: #28a745;
      color: #fff;
      font-weight: 700;
      padding: 0 5px;
    }
  }

  @keyframes marquee {
    0% {
      transform: translateX(-100%);
    }

    100% {
      transform: translateX(350%);
    }
  }

  .connected-users-now-marquee {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 10px;

    .connected-users-now {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      color: #fff;
      margin-left: 10px;

      .label {
        margin: 0 5px;
      }
    }

    .marquee {
      flex: 1;
      overflow: hidden;
      white-space: nowrap;

      p {
        margin: 0;
        color: #fff;
        animation-name: marquee;
        animation-duration: 15s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }
    }
  }

  .users-list {
    max-height: 547px;
    overflow-y: auto;
    direction: ltr;
  }
}
