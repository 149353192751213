.block {
  .add-block {
    margin-bottom: 10px;

    form {
      display: flex;
      align-items: center;
      flex-flow: row nowrap;

      .form-control {
        margin-right: 5px;
      }
    }
  }
}
