@import "variabels";

.site-administration {
  .site-administration-content {
    text-align: left;

    .form-group {
      margin-bottom: 5px;
    }

    .btn-site-settings {
      svg {
        margin-right: 5px;
      }
    }

    textarea {
      min-height: 70px;
    }

    label:not(.no-style) {
      background: $trikwaz;
      border-radius: 4px;
      color: #fff;
      padding: 0 5px;
    }

    .fixed-width {
      width: 100px;
    }

    input.form-control {
      padding: 0;
      height: auto;
    }

    .input-span {
      display: flex;
      align-items: center;
      margin-bottom: 5px;

      .form-control {
        margin-right: 5px;
      }

      span {
        font-weight: 700;
        font-size: 14px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .btn-save {
      svg {
        margin-right: 5px;
      }
    }

    .js-color-wrapper {
      width: 100px;
    }
  }
}
